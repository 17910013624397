import { BULL } from 'constants/theme';

import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import schemaGenerator from 'utils/schemaGenerator';
import { Location as ReactLocation } from '@reach/router';

interface Props {
  siteTitle: string;
  siteDescription: string;
  siteUrl: string;
  pageTitle: string;
  pageTitleFull: string;
  themeColor: string;
  social: {
    twitter: string;
  };
  imageUrl: string;
  location: Location;
  canonical: string;
}

const Head: React.FC<Props> = ({
  siteTitle,
  siteDescription,
  siteUrl,
  pageTitle,
  pageTitleFull = pageTitle ? `${pageTitle} ${BULL} ${siteTitle}` : siteTitle,
  themeColor,
  social,
  imageUrl,
  location,
  canonical = location.origin + (location.pathname || ''),
  children,
}) => {
  const image = `${siteUrl}${imageUrl}` || `${siteUrl}/social.png`;
  const schema = schemaGenerator({
    location,
    canonical,
    siteUrl,
    pageTitle,
    siteTitle,
    pageTitleFull,
  });

  return (
    <Helmet>
      <html lang="en" />

      {/* General tags */}
      <title>{pageTitleFull}</title>
      <meta name="description" content={siteDescription} />
      <meta name="image" content={image} />
      <link href="/manifest.json" rel="manifest" />

      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>

      {/* OpenGraph tags */}
      <meta property="og:type" content="article" />
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={pageTitleFull} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={pageTitleFull} />
      <meta name="twitter:description" content={siteDescription} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content={`@${social.twitter}`} />
      <meta name="twitter:creator" content={`@${social.twitter}`} />

      {/* Application tags */}
      <meta name="application-name" content={siteTitle} />
      <meta name="theme-color" content={themeColor} />
      <meta name="msapplication-TileColor" content={themeColor} />
      <meta name="apple-mobile-web-app-title" content={siteTitle} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta
        name="msapplication-square70x70"
        content="/icons/mstile-70x70.png"
      />
      <meta
        name="msapplication-square144x144"
        content="/icons/mstile-144x144.png"
      />
      <meta
        name="msapplication-square150x150"
        content="/icons/mstile-150x150.png"
      />
      <meta
        name="msapplication-wide310x150"
        content="/icons/mstile-310x150.png"
      />
      <meta
        name="msapplication-square310x310"
        content="/icons/mstile-310x310.png"
      />

      {/* Icon tags */}
      <link
        href="/icons/apple-touch-icon-57x57.png"
        rel="apple-touch-icon"
        sizes="57x57"
      />
      <link
        href="/icons/apple-touch-icon-60x60.png"
        rel="apple-touch-icon"
        sizes="60x60"
      />
      <link
        href="/icons/apple-touch-icon-72x72.png"
        rel="apple-touch-icon"
        sizes="72x72"
      />
      <link
        href="/icons/apple-touch-icon-76x76.png"
        rel="apple-touch-icon"
        sizes="76x76"
      />
      <link
        href="/icons/apple-touch-icon-114x114.png"
        rel="apple-touch-icon"
        sizes="114x114"
      />
      <link
        href="/icons/apple-touch-icon-120x120.png"
        rel="apple-touch-icon"
        sizes="120x120"
      />
      <link
        href="/icons/apple-touch-icon-144x144.png"
        rel="apple-touch-icon"
        sizes="144x144"
      />
      <link
        href="/icons/apple-touch-icon-152x152.png"
        rel="apple-touch-icon"
        sizes="152x152"
      />
      <link
        href="/icons/apple-touch-icon-167x167.png"
        rel="apple-touch-icon"
        sizes="167x167"
      />
      <link
        href="/icons/apple-touch-icon-180x180.png"
        rel="icon"
        sizes="180x180"
        type="image/png"
      />

      <link
        href="/icons/favicon-32x32.png"
        rel="icon"
        sizes="32x32"
        type="image/png"
      />
      <link
        href="/icons/favicon-16x16.png"
        rel="icon"
        sizes="16x16"
        type="image/png"
      />

      {children}
    </Helmet>
  );
};

const HeadWithQuery: React.FC<Optional<Props>> = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteTitleShort
            siteDescription
            siteUrl
            themeColor
            social {
              twitter
            }
          }
        }
      }
    `}
    render={data => (
      <ReactLocation>
        {({ location }: { location: Location }) => (
          <Head {...data.site.siteMetadata} {...props} location={location} />
        )}
      </ReactLocation>
    )}
  />
);

export default HeadWithQuery;
