import { NAVIGATION } from 'constants/navigation';

import React from 'react';
import { motion } from 'framer-motion';

import MenuItem from './menuItem';

const variants: any = {
  open: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const menuContainer = (props: any) => (
  <motion.ul variants={variants} className="mainnav__container">
    {NAVIGATION.map(item => (
      <MenuItem key={item.to} {...item} {...props} />
    ))}
  </motion.ul>
);

export default menuContainer;
