import * as theme from 'constants/theme';

import React from 'react';
import { motion } from 'framer-motion';

import './background.scss';

interface Props {
  type: string;
  delay: number;
}

const variants = {
  animate: (i: number) => ({
    pathLength: [1, 0, 0, 1],
    pathOffset: [0, 0, 0.5, 0],
    transition: {
      duration: 5,
      ease: 'linear',
      times: [0, 0.5, 0.5, 1],
      loop: Infinity,
      repeatDelay: 0,
      delay: i * 1.4,
    },
  }),
};

const Wiggle = (props: Props) => (
  <svg width="26" height="26" viewBox="0 0 26 26" className="bg__pattern">
    <motion.path
      d="M20.672,2.08c0,0 0.903,4.552 0,5.462c-0.904,0.91 -4.518,-0.91 -5.422,0c-0.903,0.91 0.904,4.551 0,5.462c-0.903,0.91 -4.518,-0.909 -5.421,0c-0.902,0.909 0.911,4.546 0.008,5.454c-0.903,0.909 -4.517,-0.91 -5.422,0c-0.903,0.91 0,5.462 0,5.462"
      fill="none"
      stroke={props.type}
      strokeWidth="3"
      animate="animate"
      variants={variants}
      custom={props.delay}
    />
  </svg>
);

const Line = (props: Props) => (
  <svg width="26" height="26" viewBox="0 0 26 26" className="bg__pattern">
    <motion.path
      d="M22.506,3.414l-19.092,19.092l19.092,-19.092Z"
      stroke={props.type}
      strokeWidth="4"
      animate="animate"
      variants={variants}
      custom={props.delay}
    />
  </svg>
);

const Background = () => {
  return (
    <div className="app__bg">
      <Line type={theme.COLOR_PRIMARY} delay={1} />
      <Wiggle type={theme.COLOR_MUTED} delay={2} />
      <Line type={theme.COLOR_SUCCESS} delay={3} />
      <Wiggle type={theme.COLOR_SECONDARY} delay={4} />
      <Line type={theme.COLOR_SECONDARY} delay={5} />
      <Wiggle type={theme.COLOR_SUCCESS} delay={1} />
      <Wiggle type={theme.COLOR_PRIMARY} delay={2} />
      <Line type={theme.COLOR_MUTED} delay={3} />
      <Wiggle type={theme.COLOR_PRIMARY} delay={4} />
      <Line type={theme.COLOR_SECONDARY} delay={5} />
    </div>
  );
};

export default Background;
