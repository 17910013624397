import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'clsx';

import Head from 'components/layout/head';
import Header from 'components/layout/header';
import Footer from 'components/layout/footer';
import Background from 'components/layout/background';

import 'styles/app.scss';

interface Props {
  type?: string;
  dark?: boolean;
  data: {
    site: {
      siteMetadata: {
        siteTitleShort: string;
      };
    };
  };
}

const Layout: React.FC<Props> = ({ data, type, dark, children }) => (
  <div className={`app ` + 'app__' + (type || 'default')}>
    <Head />
    <Header title={data.site.siteMetadata.siteTitleShort} />
    <main
      className={classNames('main', {
        ['main--dark']: dark,
      })}>
      {children}
    </main>
    <Footer dark={dark} />
    <Background />
  </div>
);

const LayoutWithQuery: React.FC<Omit<Props, 'data'>> = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitleShort
          }
        }
      }
    `}
    render={data => <Layout data={data} type={props.type} {...props} />}
  />
);

export default LayoutWithQuery;
