import React from 'react';
import { motion } from 'framer-motion';

const Path = (props: any) => (
  <motion.path
    stroke="#ffffff"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  />
);

const variantTop = {
  closed: { d: 'M 1 8 L 25 8' },
  open: { d: 'M 6 20 L 20 6' },
};

const variantBottom = {
  closed: { d: 'M 1 16 L 16 16' },
  open: { d: 'M 6 6 L 20 20' },
};

const menuToggle = ({ toggle }: any) => (
  <button className="mainnav__icon" onClick={toggle} aria-label="Toggle menu">
    <svg width="26" height="26" viewBox="0 0 26 26">
      <Path variants={variantTop} />
      <Path variants={variantBottom} />
    </svg>
  </button>
);

export default menuToggle;
