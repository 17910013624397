import BREAKPOINTS from 'constants/breakpoints';

import React, { useRef } from 'react';
import useDimensions from 'utils/useDimensions';
import { motion, useCycle } from 'framer-motion';

import MenuToggle from './menuToggle';
import MenuContainer from './menuContainer';

import './mainnav.scss';

const MainNav = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef<HTMLDivElement>(null);
  const activeMenu = useRef<HTMLDivElement>(null);
  const { height } = useDimensions(containerRef);
  let position = '64px 64px';

  if (
    typeof window !== 'undefined' &&
    window.innerWidth <= BREAKPOINTS.MOBILE
  ) {
    position = '32px 64px';
  }

  const circleAnimation = {
    open: (height = window.innerWidth) => ({
      clipPath: `circle(${height * 2 + 200}px at ${position}`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: `circle(1px at  ${position})`,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
        delay: 0,
      },
    },
  };

  const activeMenuAnimation = {
    open: {
      opacity: 0,
      transition: {
        restDelta: 2,
      },
    },
    closed: {
      opacity: 1,
      transition: {
        restDelta: 2,
        delay: 0.5,
      },
    },
  };

  return (
    <motion.nav
      className="nav mainnav"
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}>
      <MenuToggle toggle={() => toggleOpen()} />
      <MenuContainer activeMenu={activeMenu} />
      <motion.div className="mainnav__background" variants={circleAnimation} />
      <motion.div
        className="mainnav__label"
        variants={activeMenuAnimation}
        ref={activeMenu}></motion.div>
    </motion.nav>
  );
};

export default MainNav;
