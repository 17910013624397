import { NAVIGATION } from 'constants/navigation';

import React from 'react';
import { Link } from 'gatsby';

import './footnav.scss';

const footnav = () => (
  <nav className="nav footnav">
    <ul className="footnav__container">
      {NAVIGATION.map(item => (
        <li className="footnav__item" key={item.label}>
          <Link
            to={item.to}
            className="footnav__link"
            activeClassName="footnav__link--active">
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default footnav;
