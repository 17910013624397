import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import MainNav from 'components/layout/mainnav';
import Logo from 'components/layout/logo';

import { GitHub, Twitter, Facebook, LinkedIn } from './social';

import './header.scss';

interface Props {
  title: string;
}

const SOCIAL = [
  { title: 'GitHub', icon: <GitHub />, url: 'https://github.com/finalangel' },
  {
    title: 'Twitter',
    icon: <Twitter />,
    url: 'https://twitter.com/FinalAngel',
  },
  {
    title: 'Facebook',
    icon: <Facebook />,
    url: 'https://www.facebook.com/angelo.dini.dev/',
  },
  {
    title: 'LinkedIn',
    icon: <LinkedIn />,
    url: 'https://www.linkedin.com/in/angelo-dini/',
  },
];

const header = (props: Props) => (
  <header className="header">
    <MainNav />

    <Link to="/" className="header__logo" title={props.title}>
      <Logo className="header__logo__svg" />
    </Link>

    <p className="header__social">
      {SOCIAL.map(item => (
        <motion.a
          key={item.title}
          href={item.url}
          title={item.title}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Visit my ${item.title} profile`}
          className="header__social__link">
          {item.icon}
        </motion.a>
      ))}
    </p>
  </header>
);

export default header;
