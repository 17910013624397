import React from 'react';
import classNames from 'clsx';

import FootNav from 'components/layout/footnav';

import './footer.scss';

interface Props {
  dark?: boolean;
}

const Footer: React.FC<Props> = ({ dark }) => {
  return (
    <footer className={classNames('footer', { ['footer--dark']: dark })}>
      <p className="footer__text">
        This site is powered by{' '}
        <a
          href="https://control.divio.com/"
          target="_blank"
          rel="noopener noreferrer">
          Divio Cloud
        </a>
        .{' '}
        <span className="footer__copy">
          Copyright &copy; {new Date().getFullYear()} Angelo Dini.
        </span>
      </p>

      <FootNav />
    </footer>
  );
};

export default Footer;
