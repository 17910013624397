import React from 'react';

import './grid.scss';

interface Props {
  tag?: 'div' | 'section' | 'header' | 'footer';
  size?: number;
  className?: string;
  style?: unknown;
  id?: string;
}

export const Row: React.FC<Props> = ({
  children,
  tag = 'div',
  className = '',
  ...rest
}) => {
  const TagName: any = tag as keyof JSX.IntrinsicElements;
  const cls = ['row'];

  cls.push(className);

  return (
    <TagName className={cls.join(' ')} {...rest}>
      {children}
    </TagName>
  );
};

export const Column: React.FC<Props> = ({
  children,
  tag = 'div',
  className = '',
  size,
  ...rest
}) => {
  const TagName: any = tag as keyof JSX.IntrinsicElements;
  const cls = ['column'];

  cls.push('column-' + (size || 'full'));
  cls.push(className);

  return (
    <TagName className={cls.join(' ')} {...rest}>
      {children}
    </TagName>
  );
};
