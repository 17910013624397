import {
  HOME_PATH,
  ABOUT_PATH,
  BLOG_PATH,
  CONTACT_PATH,
} from 'constants/paths';

export const NAVIGATION = [
  { to: HOME_PATH, label: 'Home', partial: false },
  { to: BLOG_PATH, label: 'Blog', partial: true },
  { to: ABOUT_PATH, label: 'About', partial: true },
  { to: CONTACT_PATH, label: 'Contact', partial: true },
];
