import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

interface Props {
  to: string;
  label: string;
  partial: boolean;
  activeMenu: any;
}

const listAnimation = {
  open: {
    y: 0,
    opacity: 1,
    display: 'block',
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

const MenuItem = (props: Props) => {
  const activeName = 'mainnav__link--active';
  const linkItem: any = useRef<HTMLAnchorElement>(null);

  // set active state
  useEffect(() => {
    const item = props.activeMenu.current;
    const base = 'mainnav__label mainnav__link--';
    let hidden = '';

    if (linkItem.current.innerText === 'Home') {
      hidden = ' hidden';
    }

    if (linkItem.current) {
      const cls = linkItem.current.getAttribute('class');

      if (cls.includes(activeName)) {
        item.innerText = linkItem.current.innerText;
        item.setAttribute(
          'class',
          base + linkItem.current.innerText.toLowerCase() + hidden
        );
      }
    }
  });

  return (
    <motion.li
      whileHover={{ scale: 1.1 }}
      className="mainnav__item"
      variants={listAnimation}>
      <Link
        to={props.to}
        className="mainnav__link"
        activeClassName={activeName}
        partiallyActive={props.partial}
        ref={linkItem}>
        {props.label}
      </Link>
    </motion.li>
  );
};

export default MenuItem;
