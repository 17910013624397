// This does not support nested pages (level 2 and up)
// If you're working with deeply nested pages, remove this or rework it.

interface SchemaProps {
  location: Location;
  canonical: string;
  siteUrl: string;
  pageTitle?: string;
  siteTitle: string;
  pageTitleFull: string;
}

interface Schema {
  '@context': string;
  '@type': string;
  url?: string;
  name?: string;
  alternateName?: string;
  itemListElement?: Item[];
}

interface Item {
  '@type': string;
  position: number;
  item: {
    '@id': string;
    name: string | undefined;
  };
}

export default ({
  location,
  canonical,
  siteUrl,
  pageTitle,
  siteTitle,
  pageTitleFull,
}: SchemaProps): Schema[] => {
  const isSubPage = pageTitle && location.pathname !== '/';

  const schema: Schema[] = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: canonical,
      name: pageTitle || siteTitle,
      alternateName: pageTitleFull,
    },
  ];

  if (isSubPage) {
    schema.push({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': canonical,
            name: pageTitle,
          },
        },
      ],
    });
  }

  return schema;
};
