// please refer to variables.scss to match the variables
export const BG_PRIMARY = '#182533';
export const BG_SECONDARY = '#131d27';

export const TEXT_COLOR = '#fff';
export const TEXT_PRIMARY = '#94afc7';
export const TEXT_SECONDARY = '#3a4959';

export const COLOR_PRIMARY = '#f25850';
export const COLOR_SECONDARY = '#ffdb66';
export const COLOR_MUTED = '#0cc7dd';
export const COLOR_SUCCESS = '#64d04f';
export const COLOR_ERROR = COLOR_PRIMARY;

export const BULL = '•';
export const FONT_BASE = 26;
