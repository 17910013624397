import * as theme from 'constants/theme';

import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  hover: {
    scale: 1.2,
    strokeWidth: 2,
    stroke: theme.TEXT_PRIMARY,
  },
};

const svgAttrs = {
  width: '26',
  height: '26',
  viewBox: '0 0 26 26',
  fill: theme.BG_SECONDARY,
  initial: false,
  whileHover: 'hover',
};

const pathAttrs = {
  stroke: theme.TEXT_COLOR,
  strokeWidth: '1.5',
  variants: variants,
};

export const GitHub = () => (
  <motion.svg {...svgAttrs}>
    <motion.path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.507 21.0257C5.97343 22.4733 5.97343 18.6132 4.16 18.1313M16.8531 23.92V20.186C16.9219 19.2558 16.6118 18.3373 16.001 17.6682C18.8484 17.3301 21.84 16.1821 21.84 10.9137C21.84 9.56659 21.3527 8.2707 20.4799 7.29508C20.8937 6.11645 20.8645 4.81267 20.3989 3.65671C19.9333 2.50076 19.3291 3.31968 16.8531 5.08565C14.7757 4.4855 12.5843 4.4855 10.507 5.08565C8.03097 3.31968 6.9612 3.65671 6.9612 3.65671C6.49559 4.81267 6.46637 6.11645 6.88014 7.29508C6.00076 8.27759 5.51347 9.58532 5.52007 10.9423C5.52007 16.1722 8.51167 17.3203 11.3591 17.6967C10.7558 18.36 10.4457 19.2656 10.507 20.186V23.92"
      {...pathAttrs}
    />
  </motion.svg>
);

export const Twitter = () => (
  <motion.svg {...svgAttrs}>
    <motion.path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M22.88 5.20919C22.0197 5.83601 21.0675 6.31481 20.06 6.62822C19.2956 5.72167 18.1872 5.2 17.0234 5.2C14.8164 5.2 13 7.07537 13 9.35502C13 9.37339 13 9.39177 13 9.41014V10.3381C9.7973 10.4239 6.75776 8.84355 4.91564 6.13717C3.07451 3.42978 1.32337 14.4829 9.40673 18.1929C7.55769 19.4884 5.35368 20.1387 3.12 20.0468C11.2034 24.6837 21.0844 20.0468 21.0844 9.38258C21.0834 9.12429 21.0586 8.86601 21.0122 8.61283C21.9288 7.67974 22.5754 6.50061 22.88 5.20919Z"
      {...pathAttrs}
    />
  </motion.svg>
);

export const Facebook = () => (
  <motion.svg {...svgAttrs}>
    <motion.path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.1562 3.72131H15.3441C12.7732 3.72131 10.6578 5.81587 10.6578 8.36075V11.1448H7.84566V14.8556H10.6578V22.2791H14.407V14.8556H17.2192L18.1562 11.1448H14.407V8.36075C14.407 7.85115 14.8303 7.43307 15.3441 7.43307H18.1562V3.72131Z"
      {...pathAttrs}
    />
  </motion.svg>
);

export const LinkedIn = () => (
  <motion.svg {...svgAttrs}>
    <motion.path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.9043 9.58499C20.1148 9.58499 22.7564 12.2276 22.7564 15.4381V22.2657H18.8543V15.4381C18.8543 14.368 17.9745 13.4871 16.9043 13.4871C15.8341 13.4871 14.9533 14.368 14.9533 15.4381V22.2657H11.0512V15.4381C11.0512 12.2276 13.6938 9.58499 16.9043 9.58499ZM7.14887 10.5609H3.24679V22.2661H7.14887V10.5609ZM5.19783 7.63531C6.27536 7.63531 7.14887 6.7618 7.14887 5.68427C7.14887 4.60674 6.27536 3.73323 5.19783 3.73323C4.1203 3.73323 3.24679 4.60674 3.24679 5.68427C3.24679 6.7618 4.1203 7.63531 5.19783 7.63531Z"
      {...pathAttrs}
    />
  </motion.svg>
);
